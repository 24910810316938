<template>

    <!-- <div> -->
<header id="header">
    <section class="logo-row">
      <div class="mainNavPage"> 
        <a  class="logo" @click="changeQuestion('Home')"><img src="@/assets/images/newLogo1.png" style="width: 200px;" alt="img"></a>
        <div class="navigation-row">
          <nav class="navbar navbar-expand-lg navbar-dark">
            <!-- <a  class="logo menuLogo" @click="changeQuestion('Home')"><img src="@/assets/images/newLogo1.png" alt="img"></a> -->
            
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample03" aria-controls="navbarsExample03" aria-expanded="false" aria-label="Toggle navigation">
               <img src="@/assets/images/newLogo1.png" alt="img">
               <span class="navbar-toggler-icon"></span> 
               </button>
            <div class="collapse navbar-collapse" id="navbarsExample03">
              <ul class="navbar-nav mr-auto">
                <li class="nav-item" @click="listClick('Home')" :class="{'active':(isActive == 'Home')}"> <a class="nav-link" id="homeButton"  @click="changeQuestion('Home')">Home <span class="sr-only">(current)</span></a> </li>
                <li class="nav-item dropdown" :class="{'active':(isActive == 'Speak')}" @click="listClick('Speak')"> <a class="nav-link dropdown-toggle"  id="dropdown02" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Speaking </a>
                  <div class="dropdown-menu" aria-labelledby="dropdown02"> 
                    <a class="dropdown-item" id="readAloud"  @click="changeQuestion('readAloud')">Read Aloud<span>AI Scoring</span></a> 
                    <a class="dropdown-item" id="repeateSentence" @click="changeQuestion('repeateSentence')">Repeat Sentence<span>AI Scoring</span></a> 
                    <a class="dropdown-item" id="describeImage" @click="changeQuestion('describeImage')">Describe Image<span>AI Scoring</span></a> 
                    <a class="dropdown-item" id="retellLecture" @click="changeQuestion('retellLecture')">Re-Tell Lecture<span>AI Scoring</span></a> 
                    <a class="dropdown-item" id="answerShortQuestion" @click="changeQuestion('answerShortQuestion')" style="padding-right: 90px;">Answer Short Question<span>AI Scoring</span></a> 
                  </div>
                </li>
                <li class="nav-item dropdown" :class="{'active':(isActive == 'Write')}" @click="listClick('Write')"> 
                    <a class="nav-link dropdown-toggle"  id="dropdown03" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Writing </a>
                  <div class="dropdown-menu" aria-labelledby="dropdown03">
                     <a class="dropdown-item" id="summarized" @click="changeQuestion('summarized')" style="padding-right: 90px;">Summarize Written Text <span>AI Scoring</span></a>
                     <a class="dropdown-item" id="essay" @click="changeQuestion('essay')">Write Essay <span>AI Scoring</span></a>
                    </div>
                </li>
                <li class="nav-item dropdown" :class="{'active':(isActive == 'Read')}" @click="listClick(isActive = 'Read')"> 
                    <a class="nav-link dropdown-toggle"  id="dropdown04" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Reading </a>
                  <div class="dropdown-menu" aria-labelledby="dropdown04">
                        <a class="dropdown-item" id="multiplesingle" @click="changeQuestion('multiplesingle')">Multiple Choice (Single)</a>
                        <a class="dropdown-item" id="multipledouble" @click="changeQuestion('multipledouble')">Multiple Choice (Multiple)</a> 
                        <a class="dropdown-item" id="reorder" @click="changeQuestion('reorder')">Re-order Paragraphs</a> 
                        <a class="dropdown-item" id="fillread" @click="changeQuestion('fillread')">Reading：Fill in the Blanks</a> 
                        <a class="dropdown-item" id="fillreadwrite" @click="changeQuestion('fillreadwrite')">Reading & Writing：Fill in the blanks</a> 
                    </div>
                </li>
                <li class="nav-item dropdown" :class="{'active':(isActive == 'Listen')}" @click="listClick('Listen')"> 
                    <a class="nav-link dropdown-toggle"  id="dropdown05" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Listening </a>
                  <div class="dropdown-menu" aria-labelledby="dropdown05">
                       <a class="dropdown-item" id="summarizedspoken" @click="changeQuestion('summarizedspoken')" style="padding-right: 90px;">Summarize Spoken Text <span>AI Scoring</span></a> 
                       <a class="dropdown-item" id="multiplechoicedouble" @click="changeQuestion('multiplechoicedouble')">Multiple Choice (Multiple)</a> 
                       <a class="dropdown-item" id="multiplechoicesingle" @click="changeQuestion('multiplechoicesingle')">Multiple Choice (Single)</a> 
                       <a class="dropdown-item" id="highlightcorrectsummary" @click="changeQuestion('highlightcorrectsummary')">Highlight Correct Summary</a> 
                       <a class="dropdown-item" id="missingword" @click="changeQuestion('missingword')">Select Missing Word</a> 
                       <a class="dropdown-item" id="fillblanks" @click="changeQuestion('fillblanks')">Fill in the Blanks</a> 
                       <a class="dropdown-item" id="highligtcorrectword" @click="changeQuestion('highligtcorrectword')">Highlight Incorrect Words</a> 
                       <a class="dropdown-item" id="writedictation" @click="changeQuestion('writedictation')">Write From Dictation</a> 
                    </div>
                </li>
                <template v-if="currentUser !=null">
                <li v-if="currentUser.is_admin =='T'" class="nav-item dropdown" :class="{'active':(isActive == 'Create')}" @click="listClick('Create')"> 
                    <a class="nav-link dropdown-toggle"  id="dropdown06" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Create Questions </a>
                  <div class="dropdown-menu" aria-labelledby="dropdown06"> 
                      <a class="dropdown-item" id="speaking" @click="changeQuestion('speaking')">Speaking</a> 
                      <a class="dropdown-item" id="writing" @click="changeQuestion('writing')">Writing</a> 
                      <a class="dropdown-item" id="reading" @click="changeQuestion('reading')">Reading</a> 
                      <a class="dropdown-item" id="listening" @click="changeQuestion('listening')">Listening</a> 
                    </div>
                </li>
                
                <!-- <li v-if="currentUser.user_type !='Free' | currentUser.is_admin =='T'" class="nav-item dropdown" :class="{'active':(isActive == 'PaidMock')}" @click="listClick('PaidMock')"> 
                    <a class="nav-link dropdown-toggle"  id="dropdown07" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Paid Mock</a>
                  <template  v-for="index in mockTestMax"  >
                  <div class="dropdown-menu" :key="index" aria-labelledby="dropdown07"> 
                      <a class="dropdown-item"  @click="changeQuestion('MockTest'+index)">Mock Test {{index}}</a> 
                  </div>
                  </template>
                </li> -->

                <!-- <li v-if="currentUser.user_type =='Free' | currentUser.is_admin =='T'" class="nav-item" :class="{'active':(isActive == 'FreeMock')}" @click="listClick('FreeMock')"> 
                    <a class="nav-link"  tabindex="-1" aria-disabled="true" @click="changeQuestion('freeMocktest')">Free Mock Test</a>
                </li> -->
                </template>
                <!-- <li v-if="currentUser ==null" class="nav-item" :class="{'active':(isActive == 'FreeMock')}" @click="listClick('FreeMock')"> 
                    <a class="nav-link"  tabindex="-1" aria-disabled="true" @click="changeQuestion('freeMocktest')">Free Mock</a> </li> -->
                 <!-- <li class="nav-item" :class="{'active':(isActive == 'App')}" @click="listClick('App')"> 
                    <a class="nav-link" tabindex="-1" aria-disabled="true">App</a> </li> -->
                    <!-- <li class="nav-item" :class="{'active':(isActive == 'blog')}" @click="listClick('blog')"> 
                    <a class="nav-link" tabindex="-1" aria-disabled="true">Blog</a> </li> -->
                <!-- <li class="nav-item" :class="{'active':(isActive == 'Coach')}" @click="listClick('Coach')"> 
                    <a class="nav-link" @click="changeQuestion('coaching')" tabindex="-1" aria-disabled="true">Coaching</a> </li> -->
                <!-- <li class="nav-item"> <a class="nav-link"  tabindex="-1" aria-disabled="true">Circle</a> </li> -->
               <!-- <li class="nav-item d-block d-lg-none" @click="listClick('coachingPlans')" :class="{'active':(isActive == 'coachingPlans')}"> <a class="nav-link" id="coachingPackageButton"  @click="changeQuestion('coachingPlans')">Coaching Plans <span class="sr-only">(current)</span></a> </li> -->
               <li class="nav-item d-block d-lg-none" @click="listClick('helpingVideos')" :class="{'active':(isActive == 'helpingVideos')}"> <a class="nav-link" id="helpingVideosButton"  @click="changeQuestion('helpingVideos')">Premium Videos <span class="sr-only">(current)</span></a> </li>


                <li class="nav-item"  :class="{'active':(isActive == 'settings')}"> <a class="nav-link blink_me"  @click="changeQuestion('changePlan')">Buy Plans </a> </li>

               <li class="nav-item d-block d-lg-none" @click="listClick('referalUser')" :class="{'active':(isActive == 'referalUser')}"> <a class="nav-link" id="referalUserButton"  @click="changeQuestion('referalUser')">Referral Users</a> </li>


                <li class="nav-item dropdown userMenu" v-if="currentUser !=null" :class="{'active':(isActive == 'User')}" @click="listClick('User')"> 
                    <a class="nav-link dropdown-toggle" @click="checkClass()"  id="dropdown8" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ currentUser.first_name }} {{ currentUser.last_name }}</a>
                  <div class="dropdown-menu" id="userDropDown" aria-labelledby="dropdown99" style="min-width:150px;"> 
                      <a class="dropdown-item" id="Home"  @click="changeQuestion('Home')">
                        Profile Center</a> 
                      <!-- <p class="ml-3" style="color: #f7b924!important;font-size: 10px;">{{timePeriod}} -->
                        <a class="btn btn-success changePlan" @click="changeQuestion('changePlan')">
                        Extend</a> 
                      <!-- </p> -->
                      <a v-if="currentUser.is_admin =='T'" class="dropdown-item" id="createMock"  @click="changeQuestion('createMock')">Create Mock</a> 
                      <a class="dropdown-item" id="signOut"  @click="signOut()">Sign Out</a> 
                    </div>
                </li>
                <li class="nav-item loginLink" v-if="currentUser ==null" :class="{'active':(isActive == 'Login')}" @click="listClick('Login')">
                     <a class="nav-link"  tabindex="-1" aria-disabled="true"  @click="$bvModal.show('login-Modal')">Login</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </section>
  </header>
</template>

<script>
import { mapActions } from 'vuex'
import $ from 'jquery'
import {bus} from '../main';
export default {
    props:['user'],
    name: "NavBar",
    data(){
        return{
            currentUser:this.user,
            isActive:'Home',
            mockTestMin:0,
            mockTestMax:10,
            timePeriod:'',
            selectedmodule:''
        }
    },
    methods:{
        ...mapActions({
            removeSession: 'auth/removeSession'
        }),
        changeMenuSelection(currentQuestion){
          $(".dropdown-item").removeClass('selectedItem')
          $('#'+currentQuestion).addClass('selectedItem')
          ////debugger
          if(currentQuestion=='readAloud' | currentQuestion =='repeatsentence' | currentQuestion == 'describeimage' | currentQuestion =='retellLecture' | currentQuestion=='answerShortQuestion')
          {
            this.isActive="Speak"
          }
          else if(currentQuestion=='mcqSingle' | currentQuestion =='mcqMultiple' | currentQuestion == 'reOrderPara' | currentQuestion =='readFill' | currentQuestion=='readWriteFill')
          {
            this.isActive="Read"
          }
          else if(currentQuestion=='summarized' | currentQuestion =='essay' )
          {
            this.isActive="Write"
          }
          else if(currentQuestion=='summarizedSpoken' | currentQuestion =='listMcqMultiple' | currentQuestion == 'listMcqSingle' | currentQuestion =='highLightSummary' | currentQuestion=='selectMissingWords' | currentQuestion=='listFillBlanks' | currentQuestion=='highLightWords' | currentQuestion=='writeFromDict')
          {
            this.isActive="Listen"
          }
        },
        changeQuestion(currentQuestion){
          $('.navbar-collapse.collapse.show').removeClass('show');
            this.changeMenuSelection(currentQuestion)
            this.$forceUpdate()
          var isHome=false
          debugger
            if(currentQuestion == 'Home' | currentQuestion=='changePlan' | currentQuestion ==='coachingPlans' | currentQuestion ==='helpingVideos' | currentQuestion === 'referalUser')
            {
              if(this.$ls.get('user')==null){
                this.isActive = 'Home'
                currentQuestion =''
                isHome=true
              }
              else 
              {
                if(currentQuestion=='changePlan') {
                  this.$parent.selectDashBoardMenu='settings'
                  this.isActive = 'settings'
                }
                else if(currentQuestion=='coachingPlans') this.$parent.selectDashBoardMenu='coachingPlans'
                else if(currentQuestion=='helpingVideos') this.$parent.selectDashBoardMenu='helpingVideos'
                else if(currentQuestion=='referalUser') this.$parent.selectDashBoardMenu='referalUser'
                else this.$parent.selectDashBoardMenu='dashboard'
                
                this.isActive = 'Home'
                currentQuestion ='dashboard'
                //console.log(this.$parent.selectDashBoardMenu)
              }
            }
            else if(currentQuestion == 'freeMocktest')
            {
              // current free mock id is
              this.$ls.set('mockTestId',31)
            }
            else if(currentQuestion != '')
            {
              $('.dropdown-menu').removeClass('show')
            }
            this.currentUser=this.user
          ////console.log(this.currentUser)
            //////console.log(this.verifiedUser)
            if(this.currentUser == null & currentQuestion!='dashboard' & !isHome &  currentQuestion !='coaching')
            {
                return this.$root.$emit('bv::show::modal', 'login-Modal')
            }
            this.$emit('changeQuestion', currentQuestion)
        },
        PracticeNow(){
          this.listClick('Login')
          return this.$root.$emit('bv::show::modal', 'login-Modal')
        },
        listClick(active){
          $('.dropdown-menu').removeClass('show')
          this.isActive = active
          if(active =='App')
          {
             $('html, body').animate({
                  scrollTop: $("#AppSection").offset().top
              }, 3000);
          }
          if(active=='blog')
          {
            return window.open('https://pteclasses.com/blog', '_blank');
          }
          if(active=='User'){
            this.checkClass();
            this.leftDaysPresent()
          }
        },
        checkClass(){
          //console.log($('.userMenu').hasClass("show"))
          //console.log($("#userDropDown").hasClass("show"))
          if($('.userMenu').hasClass("show") & !$("#userDropDown").hasClass("show")){
             // alert('class added')
              //console.log('add Class')
              $("#userDropDown").addClass('show')
            }
            else if(!$('.userMenu').hasClass("show") & $("#userDropDown").hasClass("show")){
              //console.log('remove class')
              $("#userDropDown").removeClass('show')
            }
        },
        signOut(){
          this.$confirm("Are you sure you want to sign out?",
                              "Confirmation",
                              "question",
                              {
                                confirmButtonText: "Yes"
                              }).then(()=>{
                                     this.removeSession().then(()=>{
                                      this.$parent.currentUser = this.currentUser = null
                                      //this.verifiedUser = false
                                      this.$emit('changeQuestion', '')
                                      this.$browserStore.clearLocal()
                                      this.listClick('Home')
                                      
                                      return this.$root.$emit('bv::show::modal', 'login-Modal')
                                  })
                              }).catch(() => {
                                return //console.log('cancel')
                              });
            
        },
        directSignOut(){
          this.removeSession().then(()=>{
                                      this.$parent.currentUser = this.currentUser = null
                                      //this.verifiedUser = false
                                      this.$emit('changeQuestion', '')
                                      this.$browserStore.clearLocal()
                                      this.listClick('Home')
                                      
                                      return this.$root.$emit('bv::show::modal', 'login-Modal')
          });
        },
        async changingQuestionByBus(data){
          //console.log(data.id)
          //console.log(data.question)
          await this.changeQuestion(data.question)
          bus.$emit('getQuestionFromComponents', data.id);
        },
        leftDaysPresent(){
          if(this.currentUser!=null)
          if(this.currentUser.is_admin!='T'){
            if(this.currentUser.user_type !='Free')
            {
              this.timePeriod=this.currentUser.user_type.toUpperCase() //+" left " +this.$ls.get('remainingDays') + ' days'
            }
            else{
              this.timePeriod='Free limited'
            }
          }
          else{
            this.timePeriod='Admin unlimited'
          }
        }
    },
    watch: {
    // whenever question changes, this function will run
    user: function (newUser) {
     this.currentUser = newUser
     if(this.currentUser != null){
       this.mockTestMax= (this.currentUser.user_type== 'Premium')?20:10;
     }
    },
    isActive:function(newValue){
        this.$ls.set('currentTab',newValue)
    },
  },
  mounted(){
      if(this.$ls.get('currentTab') != null){
          this.isActive = this.$ls.get('currentTab')
      }
      else{
          this.$ls.set('currentTab',this.isActive)
      }
      this.leftDaysPresent()
      bus.$on('changeQuestionFromComponents', this.changingQuestionByBus);
      this.changeMenuSelection(this.$ls.get('currentQuestion'))
      
      bus.$on('DirectSignOut', this.directSignOut);
  }
}
</script>

<style scoped>
.dropdown-item span{
  font-size: 11px;
    color: #e00303;
    right: 10px;
    position: absolute;
}
.blink_me {
  animation: blinker 2s linear infinite;
  color:#e00303!important
}

@keyframes blinker {
  50% {
    opacity: .2;
    color:red!important;
  }
}
.changePlan{
    color: #fff!important;
    margin-left: 30px!important;
    padding: 1px 15px!important;
    border-radius: 5px!important;
    font-weight: 600!important;
}
.selectedItem
{
  color: #fff!important;
    background-color: #1e467f!important;
}
</style>